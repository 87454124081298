#spin {
  -webkit-animation: fa-spin 12s infinite ease-in;
  animation: fa-spin 12s infinite ease-in;
}

#spin:hover {
  /* -webkit-animation-name: fa-spin;
  animation-name: fa-spin; */
  -webkit-animation-duration: .1s;
  -webkit-animation-timing-function: ease-out;
  -webkit-animation-iteration-count: 5;
  animation-duration: .1s;
  animation-timing-function: ease-out;
  animation-iteration-count: 5;
  /* -webkit-animation-name: spin-back;
  -webkit-animation-duration : 2s;
  animation-name: spin-back;
  animation-duration: 2s; */
}


@-webkit-keyframes spin-back {
  0% {color: red;};
  70% {color: green;}
}

@keyframes spin-back {
  0% {
    -webkit-animation: fa-spin 1s infinite linear;
    animation: fa-spin 1s infinite linear;
  }
  70% {
  }
}

.plus-one {
  color: green;
  font-weight: bold;
  position: relative;

  -webkit-animation-name: translation; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 1s; /* Safari 4.0 - 8.0 */
  -webkit-animation-timing-function: ease-in;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;

  animation-name: translation;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.minus-one {
  color: red;
  font-weight: bold;

  -webkit-animation-name: translation; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 1s; /* Safari 4.0 - 8.0 */
  -webkit-animation-timing-function: ease-in;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;

  animation-name: translation;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@-webkit-keyframes translation {
  from {
    opacity: 100%;
  }
  to {
    opacity: 0%;
    top: -10em;
    }
}
@keyframes translation {
  from {
    opacity: 100%;
  }
  to {
    opacity: 0%;
    top: -10em;
    }
}

.slide-in {
  animation-duration: 0.8s;
  animation-name: slidein;
}

@keyframes slidein {
  from {
    bottom: -60%;
  }
  to {
    bottom: 0%
  }
}

.slide-out {
  animation-duration: 0.8s;
  animation-name: slideout;
}

@keyframes slideout {
  from {
    bottom: 0%
  }
  to {
    bottom: -60%;
    display: none;
  }
}

.slide-in-folder-tab {
  animation-duration: 0.8s;
  animation-name: slideintab;
}

@keyframes slideintab {
  from {
    bottom: -10%
  }
  to {
    bottom: 50%
  }
}

.slide-out-folder-tab {
  animation-duration: 0.8s;
  animation-name: slideouttab;
}

@keyframes slideouttab {
  from {
    bottom: 50%
  }
  to {
    bottom: -10%;
    display: none;
  }
}

#decay-animation {
  animation: decay 3s ease-out 1 forwards
}

@keyframes decay {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

.attention-button-animation {
	border: 1px solid transparent;
	border-radius: 3px;
	animation: pulsing-colors 3.5s infinite;
	&:hover {
		border: 1px solid black;
	}
}

@keyframes pulsing-colors {
	10% {
		box-shadow: 1px 1px 3px transparent, 1px -1px 3px transparent, -1px -1px 3px transparent, -1px 1px 3px transparent
	}
	/* 25% {
		box-shadow: 2px 2px 4px maroon, 2px -2px 4px maroon, -2px -2px 4px maroon, -2px 2px 4px maroon
	} */
	50% {
		box-shadow: 3px 3px 5px blue, 3px -3px 5px blue, -3px -3px 5px blue, -3px 3px 5px blue
	}
	/* 75% {
		box-shadow: 2px 2px 4px maroon, 2px -2px 4px maroon, -2px -2px 4px maroon, -2px 2px 4px maroon
	} */
	90% {
		box-shadow: 1px 1px 3px transparent, 1px -1px 3px transparent, -1px -1px 3px transparent, -1px 1px 3px transparent
	}
}
