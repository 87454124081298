#character-details-container {
	display: grid;
	grid-template-columns: repeat(10, 1fr);
	// border: 1px solid black;
	font-size: 1vw;
}

.section-background {
	padding: 0.25em;
	background-color: #f0f0f0;
}

.detailed-layout {
	grid-template-areas:
		". . . brief brief brief brief . . ."
		". ability-scores ability-scores hp hp . . . . .";
}

.underline-input-field {
	border-bottom: 1px solid black;
	margin-bottom: 0;
}
.user-data {
	font-family: 'Lato', sans-serif;
	font-weight: 700;
	font-size: 1.2em;
}
.underline-description {
	font-size: 0.8em;
	font-family: 'Montserrat', cursive;
	font-weight: 100;
	padding-left: 3px;
}

#character-details-details-brief {
	grid-area: brief;
	display: grid;
	grid-template-columns: repeat(9, 1fr);
	grid-template-rows: repeat(3, 33%);
	grid-template-areas:
		"name name name name alignment alignment player player player"
		"cl cl cl cl cl deity deity homeland homeland"
		"race race size gender age height weight hair eyes";
	grid-column-gap: 5px;
	.fill-space {
		max-width: 100%;
		display: flex;
		flex-flow: column;
		align-self: flex-end;
	}
	.character-details-details-brief-name {
		grid-area: name;
	}
	.character-details-details-brief-alignment {
		grid-area: alignment;
	}
	.character-details-details-brief-character-level {
		grid-area: cl;
	}
	.character-details-details-brief-deity {
		grid-area: deity;
	}
	.character-details-details-brief-homeland {
		grid-area: homeland;
	}
	.character-details-details-brief-race {
		grid-area: race;
	}
	.character-details-details-brief-size {
		grid-area: size;
	}
	.character-details-details-brief-gender {
		grid-area: gender;
	}
	.character-details-details-brief-age {
		grid-area: age;
	}
	.character-details-details-brief-height {
		grid-area: height;
	}
	.character-details-details-brief-weight {
		grid-area: weight;
	}
	.character-details-details-brief-hair {
		grid-area: hair;
	}
	.character-details-details-brief-eyes {
		grid-area: eyes;
	}
}

#character-details-ability-scores {
	grid-area: ability-scores;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	grid-template-rows: repeat(7, 1fr);
}

#character-details-hp {
	grid-area: hp;
	display: grid;
	grid-template-columns: 1fr 2fr 2fr;
	grid-template-rows: repeat(4, 1fr);
}
