// VARIABLES

$ruby: #9b111e;
$sapphire: #0f52ba;
$emerald: #046307;

$tan: #cfb997;

// VARIABLES

.background {
  height: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-image: linear-gradient(#CFB997 60%, #fff);

}

.container-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.container-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.container-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.container-8 {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(9, 1fr);
	height: 80vh;
	// overflow: auto;
}

.container-6 {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.container-7 {
  display: grid;
  grid-template-columns: 3fr 3fr 3fr 3fr 3fr 3fr .1fr;
}

.container-65-35 {
  display: grid;
  grid-template-columns: 65% 35%;
}

.character-show {
  margin: .5em;
  padding: .2em;
  background-color: rgba(235, 235, 235, 0.95);
  border: solid;
  border-color: #000;
  border-radius: 1em;
}

.muted {
  font-size: 13px;
  // color: #777;
}

.enhanced {
  font-size: 20px;
  font-weight: 700;
}

.dull {
  font-size: 14px;
  color: #333;
}

.duller {
  font-size: 12px;
  color: #333;
}

.highlight:hover {
  color: #aaa;
  /* text-shadow: 1px 1px #666; */
}

.centered {
  text-align: center;
}



.show {
  padding: 0 0.5em;
}

.show td{
  text-align: center;
}

.show th{
  text-align: center;
  padding: 0 .3em;
}


.tab-list {
  padding-left: 0;
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: 4px;
  padding: 0.5rem 0.75rem;
}

.tab-list-active {
  /* background-color: grey; */
  /* border: solid #000;
  border-top-left-radius: 1em;
  border-top-right-radius: 1em; */
  border-bottom: solid 2px #000;

}


#character {
  // grid-column-start: 1;
  // grid-column-end: 9;
  // grid-row-start: 1;
  // grid-row-end: 2;
  margin: 16px 8em;
  /* background-color: rgba(235, 235, 235, 0.95); */
  /* text-align: center; */
  /* border: solid; */
  /* border-color: #000; */
  /* border-radius: 2.2em; */
  padding: .5em;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;

}

#title {
  font-size: 2.5em;
  font-weight: 700;
}

.header {
  margin-bottom: .3em;
  margin-top: .1em;
  font-size: 1.4em;
  border-bottom: 2px solid #000;
  width: 45%;
  /* border-bottom-right-radius: 25px; */
}

#saving-throw-title {
  grid-column-start: 1;
  grid-column-end: 4;
  text-align: center;
}

.nested {
  padding-left: .4em;
}

.first-col {
  grid-column-start: 1;
  grid-column-end: 2;
}
.second-col {
  grid-column-start: 2;
  grid-column-end: 3;
}
.third-col {
  grid-column-start: 3;
  grid-column-end: 4;
}

.first-row {
  grid-row-start: 1;
  grid-row-end: 2;
}
.second-row {
  grid-row-start: 2;
  grid-row-end: 3;

}
.third-row {
  grid-row-start: 3;
  grid-row-end: 4;
}


.card{
    position: relative;
    background-color: #FDFFFC;
    width: 300px;
    height: 300px;
    /* padding: 10px 20px; */
    margin: 10px;
    overflow: hidden;
    /* border: 1px solid #011627; */
    border-radius: 10px;
    box-shadow: 0 3px 5px 0 #777;
}

.dynamic-card {
	position: relative;
	background-color: #FDFFFC;
	width: 21vw;
	height: 21vw;
	margin: 1rem;
	overflow: hidden;
	border-radius: 1rem;
	box-shadow: 0 3px 5px 0 #777;
	&:hover {
		.dynamic-card-content-button {
			text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
			color: #000;
		}
	}
	.dynamic-card-content {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 48%;
		font-family: 'Righteous', cursive;
		font-size: 2em;
		text-align: center;
		text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
		color: #fff;
		line-height: 1em;
	}
	.dynamic-card-content-button {
		position: absolute;
		width: 100%;
		top: 48%;
		font-family: 'Righteous', cursive;
		font-size: 2em;
		text-align: center;
		color: #fff;
		text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
		line-height: 1em;
		background: transparent;
		border: transparent;
		:focus {
			text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
			color: #000;
			background: #000;
		}
	}
	.dynamic-card-img {
		width: 100%;
		overflow: hidden;
		object-fit: cover;
		img {
			height: 100%;
			width: 100%;
			object-fit: cover;
		}
	}
}
// .card-img{
//     /* position: inherit; */
//     width: 100%;
//     overflow: hidden;
//     object-fit: cover;
// }
//
// .card-img img{
//     height: 100%;
//     width: 100%;
//     margin-bottom: 1%;
// }

.card-content{
    position: absolute;
    justify-content: inherit;
    /* padding: 50%; */
    width: 100%;
    height: 100%;
    top: 48%;
    /* font-family: 'Roboto Slab', serif; */
    font-family: 'Righteous', cursive;
    font-size: 2em;
    /* left: 0; */
    text-align: center;
    /* height: 51%; */
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    color: #fff;
    line-height: 1em;
}

.card-char{
    position: absolute;
    justify-content: inherit;
    width: 100%;
    height: 100%;
    top: 18%;
    /* font-family: 'Roboto Slab', serif; */
    font-family: 'Righteous', cursive;
    font-size: 2em;
    text-align: center;
    text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
    color: #000;
}

.roboto {
  font-family: 'Roboto Slab', serif;

}

.card:hover .card-content{
  color: #000;
  /* font-size: .5em; */
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
}

.card:hover .card-char{
  color: #000;
  /* font-size: .5em; */
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
}

.card:hover {
  background-color: #000;
}

.fade:hover {
  position: inherit;
  width: 100%;
  height: 100%;
  background-color: #000;
}
/* .fill:hover{
  position: inherit;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 0, 0, 0.4);

  z-index: 21322;
} */
.card-img{
    /* position: inherit; */
    width: 100%;
    overflow: hidden;
    object-fit: cover;
}

.card-img img{
    height: 100%;
    width: 100%;
    margin-bottom: 1%;
}

.btn {
  background: #1a1a1a;
  font-size: 3em;
  font-weight: 700;
  display: inline-block;
  margin: 0 5%;
  padding: .78571429em 1.5em .78571429em;
  line-height: 1em;
  border-radius: .8em;
  width: 90%;
  align-items: center;
  color: #CFB997;
  /* text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff; */
  border: transparent;
}

.create-btn {
  font-family: 'Righteous', cursive;
  background: #fff;
  font-size: 1.6em;
  font-weight: 600;
  display: inline-block;
  /* margin: 0 5%; */
  /* padding: .78571429em 1.5em .78571429em; */
  padding: .15em .5em;
  border-radius: 2em;
  /* width: 90%; */
  align-items: center;
  color: green;
  /* text-shadow: -1px -1px 0 #CFB997, 1px -1px 0 #CFB997, -1px 1px 0 #CFB997, 1px 1px 0 #CFB997; */
  border: solid #000;
  margin-top: .6em;
}


.btn:hover{
  color: #CFB997;
  background: #eaeaea;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.tinted {
  background-color: rgba(0, 0, 0, 0.4);
}

.project-name {
  font-family: 'Righteous', cursive;
}

.front-and-center {
  text-align: center;
  font-size: 5em;
}

.subtitle {
  text-align: center;
  font-size: 2em;
}

.incomplete {
  font-family: 'Righteous', cursive;
  font-size: 1.5em;
  color: #CFB997;
  border: solid #fff;
  border-radius: 2em;
  margin: 0 .5em;
  padding: 0 .3em;
  background-color: #eee;
}

.complete {
  font-family: 'Righteous', cursive;
  font-size: 1.5em;
  color: green;
  border: solid #fff;
  border-radius: 2em;
  margin: 0 .5em;
  padding: 0 .3em;
  background-color: #eee;
}

.edit {
  position: sticky;
  left: 87%;
}

.notif {
  position: sticky;
  left: 100%;
}

.edit-form {
  border-radius: 1em;
  position: absolute;
  top: 20%;
  bottom: 15%;
  left: 15%;
  right: 15%;
  padding: 1em;
  background-color: rgba(255, 255, 255, 0.95);
  color: #000;
  box-shadow: 2px 2px 3px rgba(0 ,0, 0, 0.95);
  font-size: 16px;
  z-index: 1001;
  overflow: scroll;
}

/* .badge1[data-badge-1]:after {
   content:attr(data-badge-1);
   position:absolute;
   bottom: 28px;
   right:-10px;
   font-size:.7em;
   background:green;
   color:white;
   width:18px;height:18px;
   text-align:center;
   line-height:18px;
   border-radius:50%;
   box-shadow:0 0 1px #333;
}

.badge2[data-badge-2] {
   content:attr(data-badge-2);
   position:absolute;
   bottom: 28px;
   right: 20px;
   font-size:.7em;
   background: blue;
   color:white;
   width:18px;height:18px;
   text-align:center;
   line-height:18px;
   border-radius:50%;
   box-shadow:0 0 1px #333;
}

.badge3[data-badge-3]:after {
   content:attr(data-badge-3);
   position:absolute;
   bottom: 28px;
   right: -0px;
   font-size:.7em;
   background: red;
   color:white;
   width:18px;height:18px;
   text-align:center;
   line-height:18px;
   border-radius:50%;
   box-shadow:0 0 1px #333;
} */

.badge {
  /* background: #77cc51; */
  display: block;
  position: absolute;
  top: -10px;
  line-height: 16px;
  height: 16px;
  font-family: Arial, sans-serif;
  color: white;
  text-shadow: 0 1px rgba(0, 0, 0, 0.25);
  border: 1px solid;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 1px rgba(255, 255, 255, 0.3), 0 1px 1px rgba(0, 0, 0, 0.08);
  box-shadow: inset 0 1px rgba(255, 255, 255, 0.3), 0 1px 1px rgba(0, 0, 0, 0.08);
  z-index: 900;
}

.page-dimmer {
  position: fixed;
  left: 0;
  top: 0;
  /* bottom: 0; */
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  /* z-index: 1000; */
}

.black {
  color: black;
}

.green {
  color: green;
}

#right {
  position: absolute;
  right: 2em;
  top: 40%;
}

#left {
  position: absolute;
  left: 2em;
  top: 40%;
}

.spacing {
  margin: .1em;
}

.odd {
  background-color: #F5ECCD;
}

.even {
  box-shadow: 0px 1px #333, 0px -1px #333;
}

/* Rows and Columns in Character Show */
.odd-row {
  background-color: rgba(233, 233, 233, 0.90);
}
.odd-row-general {
  background-color: #b99566;
}
.even-row-general {
  background-color: #d9b99b;
}
.even-row-legendary {
  background-color: #faf0e6;
}
.even-row-rings {
  background-color: #eed9c4;
}
.even-row-magic {
  background-color: #fff0db;
}
.even-row-poisons {
  background-color: #dba766;
}
.even-row-armor {
  background-color: #d8a994;
}
.even-row-weapons {
  background-color: #963;
}
.even-row-misc {
  background-color: #cc8443;
}


#class-img {
  width: 20em;
  margin: 2em;
  overflow: hidden;
  object-fit: cover;
}
#class-img img {
  height: 100%;
  width: 100%;
}
/*
.card-img{
    width: 100%;
    overflow: hidden;
    object-fit: cover;
}

.card-img img{
    height: 100%;
    width: 100%;
    margin-bottom: 1%;
}
*/

.underline-hover:hover {
  text-decoration: underline;
  color: cyan;
	cursor: default;
}

.underline {
  text-decoration: underline;
}

#tooltip {
  position: absolute;
  left: 50px; top: 50px;
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  font-size: 12px;

  display: inline-block;
}

#tooltip:before {
  position: absolute;
  width: 9px;
  height: 9px;
  border-top: 2px solid #000;
  border-right: 0px solid #000;
  border-bottom: 0px solid #000;
  border-left: 2px solid #000;
  top: -10px;
  left: 50%;
  margin-left: -3px;
  content: '';
  transform: rotate(45deg);
  background: #000;
}

/* Tooltips! Copied from blog by Arseniy Tomkevich, url: https://medium.com/@jsmuster/building-a-tooltip-component-with-react-2de14761e02 */

#tooltip.top {
  margin-top: -5px;
}

#tooltip .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
}

#tooltip.top .tooltip-arrow {
  top: auto;
  bottom: -5px;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}

#tooltip .tooltip-inner {
  max-width: inherit;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px;
}

#tooltip.on {
  pointer-events: none;
  display: block;
}

/* End of tooltips */


#loading-dice {
  margin: 2%;
  animation: spinning 4s infinite linear;
  -webkit-animation: spinning 4s infinite linear;
}

#loading-dice-search {
  // margin: 2%;
	position: absolute;
  animation: spinning 4s infinite linear;
  -webkit-animation: spinning 4s infinite linear;
}

@keyframes spinning {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}
@-webkit-keyframes spinning {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
}

.noStyleLi {
  list-style-type: none;
  margin: 1%;
}

.flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
}

.character-campaign-card {
  margin: 1%;
  padding: 1%;
  height: 10%;
  min-height: 12em;
  max-height: 12em;
  width: 23%;
  background: white;
  overflow: auto;
  border-radius: 8%;
}
.character-campaign-card::-webkit-scrollbar {
  display: none;
}

#equipBtn {
  display: inline-block;
  margin: 0 1%;
  border-radius: 30%;
}

#tradeBtn {
  display: inline-block;
  margin: 0 1%;
  border-radius: 30%;
}

.equipped {
  border: black 1px solid;
  margin: 0% 1.5%;
  padding: .5% 1.5%;
  border-radius: 40%;
}

.pressedBtn {
  background: #ebebeb;
  box-shadow: inset 0px 0px 5px #c1c1c1;
  outline: none;
}

.trash {
  display: inline-block;
  position: absolute;
  right: 3%;
}

.bordered-table {
  border: 1px solid black;
  border-collapse: collapse;
}

#mobile-navbar {
  display: flex;
  background-color: #fff;
  width: 100%;
}

#drop-down-menu{
  z-index: 1000;
  background-color: #fff;
  position: absolute;
}

.ui.secondary.pointing.menu a.item:active{
  background-color: grey;
}

.mobile-character-button{
  margin: .2em;
  background-color: grey;
  border-radius: 20px;
  padding-left: 1em;
}

.mobile-character-button:active{
  background-color: white;
}

#mobile-tabs{
  position: fixed;
  bottom: 0px;
  width: 100%;
}

.mobile-tab-li{
  list-style-type: none;
  margin: 0;
  width: 25%;
  border: 2px solid #000;
  /* height: 100%; */
  text-align: center;
  bottom: 0px;
  padding: 1%;
  background-color: #c0c0c0;
  border-radius: 30% 30% 0% 0%;
}

.mobile-active-tab{
  background-color: #808080;
}

.white-outline {
  text-shadow: -1px -1px white, 1px 1px white, -1px 1px white, 1px -1px white;
}

.mobile-centering{
  display: flex;
  justify-content: space-between;
  padding: 1%;
}

.mobile-flex-boxes{
  margin: 0.5em;
  padding: 0.2em;
  background-color: rgba(235, 235, 235, 0.95);
  border: solid;
  border-color: #000;
  border-radius: 1em;
}

#sidebar {
  background-color: rgba(235, 235, 235, 1);
  border: 2px solid black;
  margin: 1%;
	margin-bottom: -2px;
  border-radius: 2em 2em 0 0;
  padding: 2%;
  position: fixed;
	z-index: 1;
	height: 50%;
	bottom: 0px;
	overflow-y: auto;
	width: 50vw;
}

#sidebar-exit{
  background-color: rgba(235, 235, 235, 1);
  border: 2px solid black;
  padding: 0.8%;
  border-radius: 1em 1em 0 0;
  position: fixed;
	z-index: 2;
	border-bottom: none;
	display: flex;
	justify-content: space-between;
	font-size: 12px;
}

.standard-container-bubble {
  background-color: rgba(235, 235, 235, 0.95);
  margin: 0.25em 0.5em;
  border: 3px solid #000;
  border-radius: 0.5em;
  box-shadow: 5px 4px 2px $ruby;
  color: $sapphire;
  padding: 0.25em;
}

.spinning-die {
  animation: spinning 4s infinite linear;
  -webkit-animation: spinning 4s infinite linear;
}

.home-btn-create-links {
  // background: $ruby;
	background: linear-gradient(to right, white, black);
  font-size: 3em;
  font-weight: 700;
  display: inline-block;
  margin: 0 1%;
  padding: 0.5em;
  border-radius: .8em;
  width: 48%;
  text-align: center;
  color: $tan;
  text-shadow: -2px -2px 0 $sapphire, 2px -2px 0 $sapphire, -2px 2px 0 $sapphire, 2px 2px 0 $sapphire;
  /* text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff; */
  // border: 3px solid $sapphire;
  border: 3px solid $ruby;
}

.home-btn-create-links:hover{
  // color: $ruby;
  // background-color: $sapphire;
  color: $ruby;
  border: 3px solid $sapphire;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}


#new-character-validation-bubbles {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#known-spells-popup {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: 1fr;
	grid-template-areas:
		"known all-spells";
	text-align: left;
	height: 100%;
	table {
		border: 1px solid black;
		border-collapse: collapse;
		width: 100%;
		th {
			border-bottom: 1px solid black;
		}
		td {
			border-bottom: 1px solid black;
		}
		tr:hover {
			background: #a0a0a0;
		}
	}
}

#user-home {
	display: grid;
	grid-template-columns: 55% 45%;
	grid-template-areas:
		"characters campaigns";
	#user-characters {
		grid-area: characters;
	}
	#user-campaigns {
		grid-area: campaigns;
	}
}

#class-table {
	#class-table-fort {
		width: 7%;
	}
	#class-table-ref {
		width: 7%;
	}
	#class-table-will {
		width: 7%;
	}
	#class-table-level {
		width: 5%;
	}
}

.feature-title {
	background-color: lightblue;
	width: 100%;
	padding: 0.25em;
	margin: 0.2em;
}

.generic-table {
	th {
		background-color: lightblue;
	}
	th, tr {
		text-align: center;
	}
	tr:nth-child(even) {
		background-color: gainsboro;
	}
}

.menu {
	display: flex;
	justify-content: space-between;
	margin: 1rem 0;
	font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
	border: 1px solid rgba(34, 36, 38, .15);
	box-shadow: 0 1px 2px 0 rgba(34, 36, 38, .15);
	border-radius: .28rem;
	min-height: 2.85em;
	font-size: 1rem;
	background: #fff;
	list-style-type: none;
	padding: 0;
	&:first-child {
		margin-top: 0;
	}
	div {
		display: flex;
	}
	.item {
		position: relative;
		vertical-align: middle;
		line-height: 1;
		text-decoration: none;
		flex: 0 0 auto;
		padding: .92em 1.1em;
		color: rgba(0, 0, 0, .87);
		font-weight: 400;
		&:before {
			position: absolute;
			content: "";
			top: 0;
			right: 0;
			height: 100%;
			width: 1px;
			background: rgba(34, 36, 38, .1)
		}
	}
}

.noir-color {
	background-color: #000;
	color: #fff;
	padding: 0.18em 0.35em;
	vertical-align: middle;
}

.dynamic-size {
	@media (max-width: 1500px){
		font-size: 13px;
		// width: 110%;
	}
	@media (max-width: 1350px){
		font-size: 12px;
		// width: 120%;
	}
	@media (max-width: 1200px){
		// width: 140%;
	}
}

.dynamic-size-small {
	@media (max-width: 1500px){
		font-size: 12px;
		// width: 110%;
	}
	@media (max-width: 1350px){
		font-size: 11px;
		// width: 120%;
	}
	@media (max-width: 1200px){
		font-size: 10px;
		// width: 140%;
	}
}

.dynamic-size-smaller {
	@media (max-width: 1500px){
		font-size: 11px;
		// width: 110%;
	}
	@media (max-width: 1350px){
		font-size: 10px;
		// width: 120%;
	}
	@media (max-width: 1200px){
		font-size: 9px;
		// width: 140%;
	}
}
