.character-show-container {
  margin: 0.5vw 4vw;
	display: grid;
	grid-template-columns: repeat(14, 1fr);
	grid-template-rows: repeat(9, 1fr);
	height: 80vh;
	grid-template-areas:
		"ability ability ability ability ability . . . . hp hp skills skills skills"
		"saves saves saves size size round-actions round-actions round-actions round-actions ac ac skills skills skills"
		"features-traits features-traits features-traits features-traits actions actions actions actions actions actions actions skills skills skills"
		"features-traits features-traits features-traits features-traits actions actions actions actions actions actions actions skills skills skills"
		"features-traits features-traits features-traits features-traits actions actions actions actions actions actions actions skills skills skills"
		"features-traits features-traits features-traits features-traits actions actions actions actions actions actions actions skills skills skills"
		"features-traits features-traits features-traits features-traits actions actions actions actions actions actions actions skills skills skills"
		"features-traits features-traits features-traits features-traits actions actions actions actions actions actions actions skills skills skills"
		"features-traits features-traits features-traits features-traits actions actions actions actions actions actions actions skills skills skills";
	/* font-size: 1.1vw; */
}

.ability {
	grid-area: ability;

  margin: .5em;
}

#features-traits {
	grid-area: features-traits;

  overflow: auto;
  height: 100%;
  position: relative;
}

.hp {
	grid-area: hp;

  margin-top: 2em;
  margin: .5em;
  padding: .2em;
  background-color: rgba(235, 235, 235, 0.95);
  border: solid;
  border-color: #000;
  border-radius: 1.5em;
}


#saves {
  grid-area: saves;

  margin: .5em;
  padding: .2em;
  background-color: rgba(235, 235, 235, 0.95);
  border: solid;
  border-color: #000;
  border-radius: 1em;
}

#attack-bonus {
  margin: .5em;
  padding: .2em;
  background-color: rgba(235, 235, 235, 0.95);
  border: solid;
  border-color: #000;
  border-radius: 1em;
}

#ac {
	grid-area: ac;

  margin: .5em;
  padding: .2em;
  background-color: rgba(235, 235, 235, 0.95);
  border: solid;
  border-color: #000;
  border-radius: 1em;
  padding-top: 8%;
}

#details {
  /* grid-column-start: 5;
  grid-column-end: 9;
  grid-row-start: 1;
  grid-row-end: 8; */
  overflow: auto;
  max-height: 100%;
  position: relative;
}

#campaign{
  /* grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 8; */
  overflow: auto;
  max-height: 29em;
  position: relative;
}

#skills {
	grid-area: skills;

  margin: .5em;
  padding: .6em;
  background-color: rgba(235, 235, 235, 0.95);
  border: solid;
  border-color: #000;
  border-radius: 1em;
  overflow: auto;
  max-height: 100%;
	height: 100%;
  position: relative;
}

.actions {
	grid-area: actions;

  margin: .5em;
  padding: .2em;
  background-color: rgba(235, 235, 235, 0.95);
  border: solid;
  border-color: #000;
  border-radius: 1em;
  overflow: auto;
  height: 100%;
  position: relative;
}

.action-container {
  display: grid;
  grid-template-columns: 3fr 3fr 3fr 2fr;
  grid-template-rows: 1fr 1fr;
	grid-gap: 4px;
	grid-template-areas:
		"full-round full-round immediate free"
		"standard move swift start-turn";
	list-style: none;
}

#actions {
	grid-area: round-actions;
}

#size{
	grid-area: size;

  overflow: auto;
  max-height: 29em;
  position: relative;
}

.egg {
  background-color: rgba(235, 235, 235, 0.95);
  border: solid;
  border-color: #190B28;
  border-radius: 2.2em;
  margin: .15em;
}


.shadow {
  box-shadow: 5px 4px 2px maroon;
}

.strike {
  text-decoration: line-through;
}

.green-notif {
  position: absolute;
  background: green;
  padding: .2%;
  padding-left: 1%;
  border-radius: 50%;

}




.notification {
  text-decoration: none;
  position: relative;
  display: inline-block;
}

.notification .green-badge {
  background: #77cc51;
  position: absolute;
  top: -10px;
  right: -5px;
  padding: 5px 7px;
  border-radius: 50%;
}

.notification .yellow-badge {
  background: yellow;
  position: absolute;
  top: -10px;
  right: 12px;
  padding: 5px 7px;
  border-radius: 50%;
}

.notification .red-badge {
  background: red;
  position: absolute;
  top: -10px;
  right: 29px;
  padding: 5px 7px;
  border-radius: 50%;
}

.note-list {
  padding: 1%;
}

.note-list-item {
  padding: 2%;
  margin-bottom: 1%;
  list-style-type: none;
  /* background-color: grey; */
  border: solid 1px #000;
  /* border-radius: 8%; */
  box-shadow: 2px 2px #888888;
}

.note-list-item:hover
{
  background: #ebebeb;
  box-shadow: inset 0px 0px 5px #888888;
  outline: none;
}

.new-note-input {
  width: 100%;
}
.new-note-header {
  width: 85%;
  margin-top: 1%;
  margin-bottom: 1%;
  display: inline-block;
}

.note-form-edit {
  margin: 1%;
  width: 8%;
  padding: .2%;
}
.note-form-cancel {
  margin: 1%;
  margin-left: 0%;
  width: 4%;
  padding: .2%;
}

#white-space {
  white-space: pre;
}

.mobile-feature-tab{
  background-color: rgba(235, 235, 235, 0.95);
  margin: 0.25em 0.5em;
  border: 3px solid #000;
  padding-left: 4%;
}

.mobile-feature-selected-tab{
  background-color: rgba(235, 235, 235, 0.95);
  margin: 0.25em 0.5em;
  margin-bottom: 0%;
  border: 3px solid #000;
  border-bottom: none;
  padding-left: 4%;
}

.mobile-tab-top{
  border-radius: 15% 15% 0% 0% / 3.3em 3.3em 0em 0em;
}

.mobile-tab-bottom{
  border-radius: 0% 0% 15% 15% / 0em 0em 3.3em 3.3em;
}

.mobile-tab-selected-tab-container{
  background-color: rgba(235, 235, 235, 0.95);
  border: 3px solid #000;
  border-top: none;
  margin: 0.25em 0.5em;
  margin-top: 0%;
  max-height: 50%;
}

.mobile-selected-tab-content{
  overflow: auto;
}

.mobile-input-field {
  box-shadow: 1px 1px;
  margin: 2%;
  border-radius: 0.3em;
}

.mobile-input-field-number{
  width: 3em;
  text-align: center;
}

.mobile-hp-button{
  border: 2px solid black;
  width: 45%;
  font-size: 16px;
}

.mobile-hp-button:focus{
  outline: none;
}

.glow-blue:active{
  background-color: blue;
}
.glow-red:active{
  background-color: red;
}

.mobile-submit-button {
  border: 1px solid #000;
  width: 45%;
  font-size: 16px;
  border-radius: 0.5em;
  background-color: inherit;
}

.mobile-submit-button:active {
  background-color: #00f;
}

.mobile-close-button {
  border: 1px solid #000;
  background-color: #f00;
  border-radius: 0.3em;
  margin: 1%;
}

.mobile-dropdown{
  border: 1px solid #000;
  text-align: center;
}
