.narrow {
  max-width: 3em;
}

.taller {
  min-height: 3em;
}

.middle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.red {
  color: red;
}

.red:hover {
  background-color: red;
  color: white;
}

.blue {
  color: blue;
}

.blue:hover {
  background-color: blue;
  color: white;
}

.green {
  color: green;
}

.green:hover {
  background-color: green;
  color: white;
}

.hp-btn {
  background-color: transparent;
  border-radius: 6px;
  min-width: 2em;
  max-height: 1.2em;
  text-align: center;
}

.standard {
  border-color: #666;
  border-radius: 1em;
  background-color: #4666FF;
  color: #fff;
  box-shadow: 1px 1px #777;
}
.cast-standard {
  border-color: #666;
  border-radius: 1em;
  background-color: #999;
  color: #4666FF;
  box-shadow: 1px 1px #777;
}

.move {
  border-color: #666;
  border-radius: 1em;
  background-color: #228b22;
  color: #fff;
  box-shadow: 1px 1px #777;
}
.cast-move {
  border-color: #666;
  border-radius: 1em;
  background-color: #999;
  color: #228b22;
  box-shadow: 1px 1px #777;
}

.full {
  border-color: #666;
  border-radius: 1em;
  background-color: #8e4585;
  color: #fff;
  box-shadow: 1px 1px #777;
}
.cast-full {
  border-color: #666;
  border-radius: 1em;
  background-color: #999;
  color: #8e4585;
  box-shadow: 1px 1px #777;
}

.swift {
  border-color: #666;
  border-radius: 1em;
  background-color: #c98932;
  color: #fff;
  box-shadow: 1px 1px #777;
}
.cast-swift {
  border-color: #666;
  border-radius: 1em;
  background-color: #999;
  color: #c98932;
  box-shadow: 1px 1px #777;
}

.free {
  border-color: #666;
  border-radius: 1em;
  background-color: #aaa9ad;
  color: #fff;
  box-shadow: 1px 1px #777;
}

.immediate {
  border-color: #666;
  border-radius: 1em;
  background-color: #ff5349;
  color: #fff;
  box-shadow: 1px 1px #777;
}
.cast-immediate {
  border-color: #666;
  border-radius: 1em;
  background-color: #999;
  color: #ff5349;
  box-shadow: 1px 1px #777;
}

.long {
  border-color: #666;
  border-radius: 1em;
  background-color: #d4af37;
  color: #fff;
  box-shadow: 1px 1px #777;
}

.no-action {
  border-color: #666;
  border-radius: 1em;
  background-color: #313C48;
  color: #fff;
  box-shadow: 1px 1px #777;
}

.start {
  border-color: #666;
  border-radius: 1em;
  background-color: #000;
  color: #fff;
  box-shadow: 1px 1px #777;
}
.start:hover{
  background-color: #fff;
  color: #000;
}

.cannot-cast {
  border-color: #666;
  border-radius: 1em;
  background-color: #666;
  color: #000;
  box-shadow: 1px 1px #777
}

#damaged {
  color: red;
}

#temporary {
  color: blue;
}

#temp-damage {
  background: -webkit-linear-gradient(#87ceeb, #C42021);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#init {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 2;
  grid-row-end: 3;
  margin: .5em;
  padding: .2em;
  background-color: rgba(235, 235, 235, 0.95);
  border: solid;
  border-color: #000;
  border-radius: 1em;
}

#points {
  grid-column-start: 6;
  grid-column-end: 7;
  grid-row-start: 1;
  grid-row-end: 2;
  margin: .5em;
  padding: .2em;
  background-color: rgba(235, 235, 235, 0.95);
  border: solid;
  border-color: #000;
  border-radius: 1em;
}

#active {
  grid-column-start: 7;
  grid-column-end: 9;
  grid-row-start: 3;
  grid-row-end: 7;
  margin: .5em;
  padding: .2em;
  background-color: rgba(235, 235, 235, 0.95);
  border: solid;
  border-color: #000;
  border-radius: 1em;
  min-height: 23em;
}

#active span {
  margin: .2em;
  padding: .2em;
  border-radius: .5em;
  border: solid 1px red;
  background: #aaa;
}

#full-action {
  width: 100%;
  display: inline-block;
  text-align: center;
	grid-area: full-round;
}

#immediate-action {
  width: 100%;
  display: inline-block;
  text-align: center;
	grid-area: immediate;
}

#standard-action {
  width: 100%;
  display: inline-block;
  text-align: center;
	grid-area: standard;
}

#move-action {
  width: 100%;
  display: inline-block;
  text-align: center;
	grid-area: move;
}

#swift-action {
  width: 100%;
  display: inline-block;
  text-align: center;
	grid-area: swift;
}

#free-action {
  width: 100%;
  display: inline-block;
  text-align: center;
	grid-area: free;
}

#start-turn {
  width: 100%;
  display: inline-block;
  text-align: center;
	grid-area: start-turn;
}

#spell-desc {
  border-radius: 1em;
  position: absolute;
  top: 20%;
  bottom: 15%;
  left: 15%;
  right: 15%;
  padding: 1em;
  background-color: rgba(255, 255, 255, 0.95);
  color: #000;
  box-shadow: 2px 2px 3px rgba(0 ,0, 0, 0.95);
  font-size: 16px;
  z-index: 1001;
  overflow: scroll;
}

.table-details {
  width: 69%;
}

.health-box{
  height: 1em;
  display: inline-block;
  min-width: 78%;
  border: 1px solid black;
  background-color: none;
  padding: 1px;
  margin: 1%;
  border-radius: 1em;
}

.green-health-bar{
  display: block;
  width: inherit;
  height: 100%;
  border-radius: 1em;
}

.blue-health-bar{
  position: relative;
  z-index: -1;
  width: inherit;
  height: 100%;
  background-color: #0892D0;
}

#mobile-combat{
  display: flex;
  background-color: hotpink;
}

.ability-usage-buttons {
  border-color: #666;
  border-radius: 1em;
  background-color: #800020;
  color: #fff;
  box-shadow: 1px 1px #777;
}

#character-show-hp-container {
	text-align: center;
	display: flex;
	justify-content: space-around;
	ul {
		list-style: none;
		padding-left: 0;
		border: 2px solid black;
		height: 25vh;
		overflow-y: scroll;
	}
	input {
		font-size: 2em;
		height: 2.5em;
		width: 4em;
		align-self: center;
		text-align: center;
	}
	button {
		width: 10vw;
		height: 8vw;
		margin: 1vw;
		border-radius: 1em;
		font-size: 1.5em;
	}
}
