.spell-card {
  /* border: dashed #987321; */
  margin: .3em;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 1em;
  padding: .2em;
  padding-left: 1em;
  display: grid;
  grid-template-columns: 53% 9% 38%;
}

.shrink {
  max-height: 5em;
}

.mobile-tab-ability{
  border: 2px solid black;
  border-radius: 5% 5% 40% 40%;
  background-image: linear-gradient(rgba(145, 129, 81, 0.4) 25%, rgba(122, 122, 122, 0.2));
  margin: 1%;
}
